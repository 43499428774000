import { ObservedElement } from './types/ObservedElement';
import { getAttributeFromSelector } from './util/getAttributeFromSelector';

class GlobalEventHandler {
  constructor() {
    // ...
  }

  observedElements: ObservedElement[] = [];

  addElementToObserve(element: ObservedElement) {
    if (this.observedElements.find(el => el._id === element._id)) return;
    this.observedElements.push(element);
  }

  addElementsToObserve(elements: ObservedElement[]) {
    elements.forEach(element => {
      if (this.observedElements.find(el => el._id === element._id)) return;
      this.observedElements.push(element);
    });
  }

  startObserving() {
    const eventTypesToObserve = [...new Set(this.observedElements.map(el => el.eventType))];

    eventTypesToObserve.forEach(eventType => {
      window.addEventListener(eventType, (event: Event) => {
        const elements = this.observedElements.filter(el => el.eventType === eventType);

        elements.forEach(element => {
          const target = event.target as HTMLElement;

          const attribute = getAttributeFromSelector(element.selector);
          const isObservedElement = target.hasAttribute(`${attribute}`);
          const hasObservedParent = target.closest(element.selector) !== null;

          if (isObservedElement || hasObservedParent) {
            element.handler(event);
          }
        });
      });
    });
  }

  stopObserving() {
    const eventTypesToObserve = [...new Set(this.observedElements.map(el => el.eventType))];

    eventTypesToObserve.forEach(eventType => {
      window.removeEventListener(eventType, (event: Event) => {
        const elements = this.observedElements.filter(el => el.eventType === eventType);

        elements.forEach(element => {
          const target = event.target as HTMLElement;

          const attribute = getAttributeFromSelector(element.selector);
          const isObservedElement = target.hasAttribute(`${attribute}`);
          const hasObservedParent = target.closest(element.selector) !== null;

          if (isObservedElement || hasObservedParent) {
            element.handler(event);
          }
        });
      });
    });
  }

  restartObserving() {
    this.stopObserving();
    this.startObserving();
  }
}

export default GlobalEventHandler;
