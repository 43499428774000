// SERVICE CONFIGURATIONS
export const SUPPORTED_TLD = process.env.ICP_REGISTRY_TLD || 'inc';
export const GA4_MEASUREMENT_ID = process.env.GA4_MEASUREMENT_ID;

// TOOL NAMES
export enum TOOL_NAME {
  // 1st party
  REGISTRY_TRACKER = 'registry_tracker',

  // 3rd party
  GOOGLE_ANALYTICS = 'google_analytics',
  LINKEDIN = 'linkedin',
  META = 'meta',
  UET = 'uet',
}

// EVENT NAMES
export enum EVENT_NAME {
  // BNG Tool
  BNG_TOOL_FORM_SUBMIT = 'bng_tool_form_submit',
  BNG_TOOL_REGISTRAR_CLICK = 'bng_tool_registrar_click',

  // Domain Expiration Checker
  DOMAIN_EXPIRATION_CHECKER_FORM_SUBMIT = 'domain_expiry_tool_form_submit',
  DOMAIN_EXPIRATION_CHECKER_REGISTRAR_CLICK = 'domain_expiry_tool_registrar_click',

  // Domain Search
  DOMAIN_SEARCH_FORM_SUBMIT = 'domain_search_form_submit',
  DOMAIN_SEARCH_REGISTRAR_CLICK = 'domain_search_registrar_click',

  // DNG Tool
  DNG_TOOL_FORM_SUBMIT = 'dng_tool_form_submit',
  DNG_TOOL_REGISTRAR_CLICK = 'dng_tool_registrar_click',

  // Whois Search
  WHOIS_SEARCH_FORM_SUBMIT = 'whois_search_form_submit',
  WHOIS_SEARCH_REGISTRAR_CLICK = 'whois_search_registrar_click',

  // Generic
  EVENT_STRING = 'event',
  TRACK_STRING = 'track',
  TRACK_CUSTOM = 'trackCustom',
}

export enum LINKEDIN_CONVERSION_ID {
  BNG_FORM_SUBMIT = '16897236',
  BNG_REGISTRAR_CLICK = '16897244',
  DNG_FORM_SUBMIT = '16897252',
  DNG_REGISTRAR_CLICK = '16897260',
  DOMAIN_EXPIRY_FORM_SUBMIT = '16897268',
  DOMAIN_EXPIRY_REGISTRAR_CLICK = '16897276',
  DOMAIN_SEARCH_FORM_SUBMIT = '16897164',
  DOMAIN_SEARCH_REGISTRAR_CLICK = '16897172',
  WHOIS_SEARCH_FORM_SUBMIT = '16897284',
  WHOIS_SEARCH_REGISTRAR_CLICK = '16897292',
}
