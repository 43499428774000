import GlobalEventHandler from './src/GlobalEventHandler';
import { observables } from './src/config/observables';
import * as tools from './src/config/third-party-init';
import { initToolsInitializedContainer } from './src/util/toolInitialization';

initToolsInitializedContainer();
tools.initGA4();
tools.initUET();
tools.initMetaPixel();
tools.initLinkedIn();

const GLOBAL_EVENT_HANDLER = new GlobalEventHandler();
GLOBAL_EVENT_HANDLER.addElementsToObserve(observables);
GLOBAL_EVENT_HANDLER.startObserving();
