import { v4 as uuidv4 } from 'uuid';
import { sanitizeDomain } from '@repo/intercap-utility';
import { ObservedElement } from '../types/ObservedElement';
import { EVENT_NAME, LINKEDIN_CONVERSION_ID, SUPPORTED_TLD, TOOL_NAME } from './constants';
import { isRegistryTrackerUndefined } from '../util/isRegistryTrackerUndefined';
import { getIsToolInitialized } from '../util/toolInitialization';

// DOMAIN SEARCH COMPONENT
const createDomainSearchForm = (): ObservedElement => {
  const selector = '[data-domain-search-form]';

  return {
    _id: uuidv4(),
    name: 'Domain Search Form',
    selector,
    eventType: 'submit',
    handler: e => {
      if (isRegistryTrackerUndefined()) return true;

      const target = e.target as HTMLFormElement;
      const searchInput = target.querySelector('[data-domain-search-input]') as HTMLInputElement;

      if (!searchInput) {
        console.log('No input found with HTML attribute: data-domain-search-input');
        return true;
      }

      const searchText = searchInput.value;

      // sanitize and transform the domain name the same as the server does
      const sanitizedDomain = sanitizeDomain(searchText, SUPPORTED_TLD);
      void registryTracker.trackSearch(true, sanitizedDomain);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_SEARCH_FORM_SUBMIT, {
          related_domain: sanitizedDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_SEARCH_FORM_SUBMIT, {
          related_domain: sanitizedDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.DOMAIN_SEARCH_FORM_SUBMIT, {
          related_domain: sanitizedDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.DOMAIN_SEARCH_FORM_SUBMIT });
      }
    },
  };
};

const createRegistrarButton = (): ObservedElement => {
  const selector = '[data-domain-search-registrar-button]';

  return {
    _id: uuidv4(),
    name: 'Registrar Button',
    selector,
    eventType: 'click',
    handler: (e: Event) => {
      if (isRegistryTrackerUndefined()) return true;

      let targetButton = e.target as HTMLElement;
      targetButton = targetButton.closest(selector) as HTMLElement;

      void registryTracker.trackRetailerClick(true, targetButton.dataset.domainSearchRegistrarButton?.toLowerCase());

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_SEARCH_REGISTRAR_CLICK, {
          registrar: targetButton.dataset.domainSearchRegistrarButton?.toLowerCase(),
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_SEARCH_REGISTRAR_CLICK, {
          registrar: targetButton.dataset.domainSearchRegistrarButton?.toLowerCase(),
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.DOMAIN_SEARCH_REGISTRAR_CLICK, {
          registrar: targetButton.dataset.domainSearchRegistrarButton?.toLowerCase(),
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.DOMAIN_SEARCH_REGISTRAR_CLICK });
      }
    },
  };
};

// WHOIS SEARCH COMPONENT
const createWhoisSearchForm = (): ObservedElement => {
  const selector = '[data-whois-search-form]';

  return {
    _id: uuidv4(),
    name: 'Whois Search Form',
    selector,
    eventType: 'submit',
    handler: e => {
      if (isRegistryTrackerUndefined()) return true;

      const target = e.target as HTMLFormElement;
      const searchInput = target.querySelector('[data-whois-search-input]') as HTMLInputElement;

      if (!searchInput) {
        console.log('No input found with HTML attribute: data-whois-search-input');
        return true;
      }

      const searchText = searchInput.value;

      // sanitize and transform the domain name the same as the server does
      const sanitizedDomain = sanitizeDomain(searchText, SUPPORTED_TLD);
      void registryTracker.trackWhoisSearch(true, searchText.toLowerCase(), sanitizedDomain);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.WHOIS_SEARCH_FORM_SUBMIT, {
          related_domain: sanitizedDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.WHOIS_SEARCH_FORM_SUBMIT, {
          related_domain: sanitizedDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.WHOIS_SEARCH_FORM_SUBMIT, {
          related_domain: sanitizedDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.WHOIS_SEARCH_FORM_SUBMIT });
      }
    },
  };
};

export const createWhoisRegistrarButton = (): ObservedElement => {
  const selector = '[data-whois-search-registrar-button]';

  return {
    _id: uuidv4(),
    name: 'Whois Search Registrar Button',
    selector,
    eventType: 'click',
    handler: (e: Event) => {
      if (isRegistryTrackerUndefined()) return true;

      let targetButton = e.target as HTMLElement;
      targetButton = targetButton.closest(selector) as HTMLElement;

      void registryTracker.trackWhoisRegistrarClick(
        true,
        targetButton.dataset.whoisSearchRegistrarButton?.toLowerCase(),
      );

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.WHOIS_SEARCH_REGISTRAR_CLICK, {
          registrar: targetButton.dataset.whoisSearchRegistrarButton?.toLowerCase(),
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.WHOIS_SEARCH_REGISTRAR_CLICK, {
          registrar: targetButton.dataset.whoisSearchRegistrarButton?.toLowerCase(),
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.WHOIS_SEARCH_REGISTRAR_CLICK, {
          registrar: targetButton.dataset.whoisSearchRegistrarButton?.toLowerCase(),
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.WHOIS_SEARCH_REGISTRAR_CLICK });
      }
    },
  };
};

// DNG TOOL COMPONENT
const createDNGQueryForm = (): ObservedElement => {
  const selector = '[data-domain-name-generator-form]';
  // secondary targets
  const keywordsInputSelector = '[data-domain-name-generator-keywords-input]';
  const industryInputSelector = '[data-domain-name-generator-industry-input]';
  const currentDomainInputSelector = '[data-domain-name-generator-current-domain-input]';

  return {
    _id: uuidv4(),
    name: 'DNG Query Form',
    selector,
    eventType: 'submit',
    handler: e => {
      if (isRegistryTrackerUndefined()) return true;

      const target = e.target as HTMLFormElement;
      const keywordsInput: HTMLInputElement | null =
        target.querySelector(keywordsInputSelector)?.tagName === 'INPUT'
          ? (target.querySelector(keywordsInputSelector) as HTMLInputElement)
          : (target.querySelector(keywordsInputSelector)?.querySelector('input') as HTMLInputElement);

      const industryInput: HTMLInputElement | null =
        target.querySelector(industryInputSelector)?.tagName === 'INPUT'
          ? (target.querySelector(industryInputSelector) as HTMLInputElement)
          : (target.querySelector(industryInputSelector)?.querySelector('input') as HTMLInputElement);

      const currentDomainInput: HTMLInputElement | null =
        target.querySelector(currentDomainInputSelector)?.tagName === 'INPUT'
          ? (target.querySelector(currentDomainInputSelector) as HTMLInputElement)
          : (target.querySelector(currentDomainInputSelector)?.querySelector('input') as HTMLInputElement);

      if (!keywordsInput) {
        console.log('No input found with HTML attribute: data-domain-name-generator-keywords-input');
        return true;
      }

      const keywords = keywordsInput.value;
      // optional fields
      const industry = industryInput?.value.length ? industryInput.value : undefined;
      const currentDomain = currentDomainInput?.value.length ? currentDomainInput.value : undefined;

      void registryTracker.trackDNGQuery(true, keywords, industry, currentDomain);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.DNG_TOOL_FORM_SUBMIT, {
          query_keywords: keywords,
          industry,
          current_domain: currentDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.DNG_TOOL_FORM_SUBMIT, {
          query_keywords: keywords,
          industry,
          current_domain: currentDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.DNG_TOOL_FORM_SUBMIT, {
          query_keywords: keywords,
          industry,
          current_domain: currentDomain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.DNG_FORM_SUBMIT });
      }
    },
  };
};

const createDNGRegistrarButton = (): ObservedElement => {
  const selector = '[data-domain-name-generator-registrar-button]';
  // secondary targets
  const relatedDomainAncestorSelector = '[data-domain-name-generator-result-item]';
  const relatedDomainSelector = '[data-domain-name-generator-domain-name]';

  return {
    _id: uuidv4(),
    name: 'DNG Registrar Button',
    selector,
    eventType: 'click',
    handler: (e: Event) => {
      if (isRegistryTrackerUndefined()) return true;

      let targetButton = e.target as HTMLElement;
      targetButton = targetButton.closest(selector) as HTMLElement;

      const relatedDomainAncestor = targetButton.closest(relatedDomainAncestorSelector) as HTMLElement;

      if (!relatedDomainAncestor) {
        console.log('No ancestor found with HTML attribute: data-domain-name-generator-result-item');
        return true;
      }

      const relatedDomainElement = relatedDomainAncestor.querySelector(relatedDomainSelector) as HTMLElement;

      if (!relatedDomainElement) {
        console.log('No element found with HTML attribute: data-domain-name-generator-domain-name');
        return true;
      }

      const registrar_name = targetButton.dataset.domainNameGeneratorRegistrarButton?.toLowerCase();
      const related_domain = relatedDomainElement.dataset.domainNameGeneratorDomainName?.toLowerCase();

      void registryTracker.trackDNGRegistrarClick(true, registrar_name, related_domain);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.DNG_TOOL_REGISTRAR_CLICK, {
          registrar_name,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.DNG_TOOL_REGISTRAR_CLICK, {
          registrar_name,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.DNG_TOOL_REGISTRAR_CLICK, {
          registrar_name,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.DNG_REGISTRAR_CLICK });
      }
    },
  };
};

// BNG TOOL COMPONENT
const createBNGQueryForm = (): ObservedElement => {
  const selector = '[data-business-name-generator-form]';
  // secondary targets
  const keywordsInputSelector = '[data-business-name-generator-keywords-input]';
  const industryInputSelector = '[data-business-name-generator-industry-input]';

  return {
    _id: uuidv4(),
    name: 'BNG Query Form',
    selector,
    eventType: 'submit',
    handler: e => {
      if (isRegistryTrackerUndefined()) return true;

      const target = e.target as HTMLFormElement;
      const keywordsInput: HTMLInputElement | null =
        target.querySelector(keywordsInputSelector)?.tagName === 'INPUT'
          ? (target.querySelector(keywordsInputSelector) as HTMLInputElement)
          : (target.querySelector(keywordsInputSelector)?.querySelector('input') as HTMLInputElement);

      const industryInput: HTMLInputElement | null =
        target.querySelector(industryInputSelector)?.tagName === 'INPUT'
          ? (target.querySelector(industryInputSelector) as HTMLInputElement)
          : (target.querySelector(industryInputSelector)?.querySelector('input') as HTMLInputElement);

      if (!keywordsInput) {
        console.log('No input found with HTML attribute: data-business-name-generator-keywords-input');
        return true;
      }

      const keywords = keywordsInput.value;
      // optional fields
      const industry = industryInput?.value.length ? industryInput.value : undefined;

      void registryTracker.trackBNGQuery(true, keywords, industry);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.BNG_TOOL_FORM_SUBMIT, {
          query_keywords: keywords,
          industry,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.BNG_TOOL_FORM_SUBMIT, {
          query_keywords: keywords,
          industry,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.BNG_TOOL_FORM_SUBMIT, {
          query_keywords: keywords,
          industry,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.BNG_FORM_SUBMIT });
      }
    },
  };
};

const createBNGRegistrarButton = (): ObservedElement => {
  const selector = '[data-business-name-generator-registrar-button]';
  // secondary targets
  const relatedDomainAncestorSelector = '[data-business-name-generator-result-item]';
  const relatedDomainSelector = '[data-business-name-generator-domain-name]';

  return {
    _id: uuidv4(),
    name: 'BNG Registrar Button',
    selector,
    eventType: 'click',
    handler: (e: Event) => {
      if (isRegistryTrackerUndefined()) return true;

      let targetButton = e.target as HTMLElement;
      targetButton = targetButton.closest(selector) as HTMLElement;

      const relatedDomainAncestor = targetButton.closest(relatedDomainAncestorSelector) as HTMLElement;

      if (!relatedDomainAncestor) {
        console.log('No ancestor found with HTML attribute: data-business-name-generator-result-item');
        return true;
      }

      const relatedDomainElement = relatedDomainAncestor.querySelector(relatedDomainSelector) as HTMLElement;

      if (!relatedDomainElement) {
        console.log('No element found with HTML attribute: data-business-name-generator-domain-name');
        return true;
      }

      const registrar_name = targetButton.dataset.businessNameGeneratorRegistrarButton?.toLowerCase();
      const related_domain = relatedDomainElement.dataset.businessNameGeneratorDomainName;

      void registryTracker.trackBNGRegistrarClick(true, registrar_name, related_domain);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.BNG_TOOL_REGISTRAR_CLICK, {
          registrar_name,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.BNG_TOOL_REGISTRAR_CLICK, {
          registrar_name,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.BNG_TOOL_REGISTRAR_CLICK, {
          registrar_name,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.BNG_REGISTRAR_CLICK });
      }
    },
  };
};

// DOMAIN EXPIRATION CHECKER COMPONENT
const createDECQueryForm = (): ObservedElement => {
  const selector = '[data-domain-expiration-checker-form]';
  // secondary targets
  const queryDomainInputSelector = '[data-domain-expiration-checker-query-input]';

  return {
    _id: uuidv4(),
    name: 'DEC Query Form',
    selector,
    eventType: 'submit',
    handler: e => {
      if (isRegistryTrackerUndefined()) return true;

      const target = e.target as HTMLFormElement;
      const queryDomainInput: HTMLInputElement | null =
        target.querySelector(queryDomainInputSelector)?.tagName === 'INPUT'
          ? (target.querySelector(queryDomainInputSelector) as HTMLInputElement)
          : (target.querySelector(queryDomainInputSelector)?.querySelector('input') as HTMLInputElement);

      if (!queryDomainInput) {
        console.log('No input found with HTML attribute: data-domain-expiration-checker-query-input');
        return true;
      }

      const query_domain = queryDomainInput.value;

      const related_domain = sanitizeDomain(query_domain, SUPPORTED_TLD);

      void registryTracker.trackDECQuery(true, query_domain, related_domain);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_EXPIRATION_CHECKER_FORM_SUBMIT, {
          query_domain,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_EXPIRATION_CHECKER_FORM_SUBMIT, {
          query_domain,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.DOMAIN_EXPIRATION_CHECKER_FORM_SUBMIT, {
          query_domain,
          related_domain,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.DOMAIN_EXPIRY_FORM_SUBMIT });
      }
    },
  };
};

const createDECRegistrarButton = (): ObservedElement => {
  const selector = '[data-domain-expiration-checker-registrar-button]';

  return {
    _id: uuidv4(),
    name: 'DEC Registrar Button',
    selector,
    eventType: 'click',
    handler: (e: Event) => {
      if (isRegistryTrackerUndefined()) return true;

      let targetButton = e.target as HTMLElement;
      targetButton = targetButton.closest(selector) as HTMLElement;

      const registrar_name = targetButton.dataset.domainExpirationCheckerRegistrarButton?.toLowerCase();

      void registryTracker.trackDECRegistrarClick(true, registrar_name);

      if (getIsToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS)) {
        void gtag(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_EXPIRATION_CHECKER_REGISTRAR_CLICK, {
          registrar_name,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.UET)) {
        void window.uetq.push(EVENT_NAME.EVENT_STRING, EVENT_NAME.DOMAIN_EXPIRATION_CHECKER_REGISTRAR_CLICK, {
          registrar_name,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.META)) {
        void fbq(EVENT_NAME.TRACK_CUSTOM, EVENT_NAME.DOMAIN_EXPIRATION_CHECKER_REGISTRAR_CLICK, {
          registrar_name,
        });
      }

      if (getIsToolInitialized(TOOL_NAME.LINKEDIN)) {
        void window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID.DOMAIN_EXPIRY_REGISTRAR_CLICK });
      }
    },
  };
};

export const observables: ObservedElement[] = [
  createDomainSearchForm(),
  createRegistrarButton(),
  createWhoisSearchForm(),
  createWhoisRegistrarButton(),
  createDNGQueryForm(),
  createDNGRegistrarButton(),
  createBNGQueryForm(),
  createBNGRegistrarButton(),
  createDECQueryForm(),
  createDECRegistrarButton(),
];
