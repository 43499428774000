"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitizeDomain = void 0;
const punycode_1 = require("punycode/");
const common_tld_list = ['com', 'org', 'net', 'gov', 'edu', 'mil', 'co', 'io', 'tv', 'me', 'info', 'biz'];
const DEFAULT_DOMAIN_NAME = 'a';
const sanitizeDomain = (domain, tld) => {
    const validDomainRegex = new RegExp(`^(?!.*\\..*\\.)[a-zA-Z0-9]+([a-zA-Z0-9-]+)*$`);
    let convertedDomain = (0, punycode_1.toASCII)(domain).toLocaleLowerCase();
    //remove all non-alphanumeric characters except hyphens and periods
    convertedDomain = convertedDomain.replace(/[^a-z0-9.-]/g, '');
    //check for subdomains
    if (convertedDomain.split('.').length > 2) {
        //keep only the last two parts of the domain
        const domainParts = convertedDomain.split('.');
        convertedDomain = `${domainParts[domainParts.length - 2]}.${domainParts[domainParts.length - 1]}`;
    }
    //remove TLD for easier filtering
    if (convertedDomain.endsWith(`.${tld}`)) {
        convertedDomain = convertedDomain.split('.')[0];
    }
    //remove common TLDs for easier filtering
    common_tld_list.forEach(commonTLD => {
        if (convertedDomain.endsWith(`.${commonTLD}`)) {
            convertedDomain = convertedDomain.split('.')[0];
        }
    });
    //if there is still a dot, keep only the largest part of the domain
    if (convertedDomain.split('.').length > 1) {
        convertedDomain = convertedDomain.split('.').sort((a, b) => b.length - a.length)[0];
    }
    //check if convertedDomain only contains hyphens or periods
    if (/^[.-]+$/.test(convertedDomain) || convertedDomain === `.${tld}` || convertedDomain === '') {
        return `${DEFAULT_DOMAIN_NAME}.${tld}`;
    }
    if (convertedDomain.startsWith('-') || convertedDomain.startsWith('.')) {
        convertedDomain = convertedDomain.slice(1);
        return (0, exports.sanitizeDomain)(convertedDomain, tld);
    }
    if (convertedDomain.endsWith('-') || convertedDomain.endsWith('.')) {
        convertedDomain = convertedDomain.slice(0, convertedDomain.length - 1);
        return (0, exports.sanitizeDomain)(convertedDomain, tld);
    }
    if (validDomainRegex.test(convertedDomain)) {
        return `${convertedDomain}.${tld}`;
    }
    return convertedDomain;
};
exports.sanitizeDomain = sanitizeDomain;
