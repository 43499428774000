"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBoolean = void 0;
const parseBoolean = (value) => {
    if (value === undefined || value === null) {
        return false;
    }
    if (typeof value === 'boolean') {
        return value;
    }
    if (typeof value === 'number') {
        return value === 1;
    }
    return (value.toLowerCase() === 'true' ||
        value.toLowerCase() === 't' ||
        value.toLowerCase() === 'yes' ||
        value.toLowerCase() === 'y' ||
        value.toLowerCase() === 'on' ||
        value === '1');
};
exports.parseBoolean = parseBoolean;
