import { setToolInitialized } from '../util/toolInitialization';
import { GA4_MEASUREMENT_ID, TOOL_NAME } from './constants';

/**
 * @description Initialize Google Analytics 4
 */
export const initGA4 = () => {
  if (typeof gtag === 'undefined') {
    const script = document.createElement('script');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA4_MEASUREMENT_ID}');
    `;
    document.head.appendChild(script);
  }
  setToolInitialized(TOOL_NAME.GOOGLE_ANALYTICS);
};

/**
 * @description Initialize Universal Event Tracking (UET) for Bing Ads
 */
export const initUET = () => {
  window.uetq = window.uetq || [];
  setToolInitialized(TOOL_NAME.UET);
};

/**
 * @description Initialize Meta Pixel for Facebook
 */
export const initMetaPixel = () => {
  setToolInitialized(TOOL_NAME.META);
};

/**
 * @description Initialize LinkedIn Insight Tag
 */
export const initLinkedIn = () => {
  setToolInitialized(TOOL_NAME.LINKEDIN);
};
