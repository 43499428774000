import { TOOL_NAME } from '../config/constants';

export const initToolsInitializedContainer = (): void => {
  window.TOOLS_INITIALIZED = window.TOOLS_INITIALIZED || [];
};

export const getIsToolInitialized = (toolName: TOOL_NAME): boolean => {
  return window.TOOLS_INITIALIZED.includes(toolName);
};

export const setToolInitialized = (toolName: TOOL_NAME): void => {
  window.TOOLS_INITIALIZED.push(toolName);
};
